.feed_count {
  height: 18px;
  width: 18px;
  padding: 8px;
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  margin: 0 0.5rem;
  border: 1px solid;
  border-radius: 50%;
  cursor: pointer;
}
.content_font {
  font-size: 11px;
}
.MuiSlider-root{
  color: #ff9e08 !important;
}

@media screen and (max-width: 600px) {
  .feed_count {
    height: 16px;
    width: 16px;
    padding: 5px;
  }
  .content_font {
    font-size: 10px;
  }
}
